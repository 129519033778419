import Swal, { SweetAlertResult } from "sweetalert2";

const swalCustom = Swal.mixin({
      customClass: {
            confirmButton: 'btn btn-primary pull-right',
            cancelButton: 'btn btn-danger',
      }
})
export async function confirm(name?: string): Promise<SweetAlertResult<any>> {
      return swalCustom.fire({
            title: `Are you sure you want to delete ${name ? name : 'this record'}?`, icon: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Yes, delete it!'
      })
}

export async function confirmationDialog(message: string, message2?: string, error?:string): Promise<SweetAlertResult<any>> {
      return swalCustom.fire(
            message,
            message2 ? message2 : '',
            error ? 'error' : 'success'
      )
}

export async function confirmLogin(userType: string): Promise<SweetAlertResult<any>> {

      if (userType?.endsWith('s')) {
            userType = userType.slice(0, userType?.lastIndexOf('s'))
      }
      return swalCustom.fire({
            title: `You Need to Login as ${userType}?`,
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Login',
      })
}